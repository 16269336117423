@import '../theme-bootstrap';

// new for 2018 Creative Refresh
// new elements - no need for specificity
.product-brief {
  &__shaded-detail {
    @include breakpoint($medium-up) {
      display: table;
      width: 100%;
    }
  }
  &__shades-wrapper {
    height: 32px;
    overflow: hidden;
    @include breakpoint($medium-up) {
      height: 28px;
    }
  }
  &__sized-detail {
    display: table;
    margin: 15px 0;
    width: 100%;
  }
  &__cta-section {
    display: table-cell;
    margin-top: 1em;
  }
  &__cta-add-to-bag {
    display: table-cell;
  }
  &__cta-waitlist {
    display: table-cell;
    vertical-align: bottom;
  }
  &__cta-spp-link {
    display: table-cell;
    padding-left: 1em;
    vertical-align: bottom;
    &:hover {
      color: $color-green;
      text-decoration: none;
    }
  }
  &__add-to-waitlist {
    padding-left: 1em;
    text-decoration: underline;
  }
  &__spp-link {
    @include secondary-hover;
    text-decoration: underline;
  }
}

// specificity until we can deprecate older styles and templates
.product-brief.product-brief-v2 {
  font-family: $font--futura-book;
  font-size: 15px;
  min-height: 200px; // @todo temporary or permanent - TBD accounts for missing images
  padding: 0;
  text-align: left;
  .product-brief {
    &__badge {
      margin: 1em;
      position: absolute;
      text-align: left;
      z-index: 1;
    }
    &__img {
      float: unset;
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      min-height: 3em;
      aspect-ratio: 1;
      &--alt {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        transition: opacity 0.3s;
      }
      &-link:hover {
        .product-brief__img--alt {
          opacity: 1;
        }
      }
    }
    &__detail {
      bottom: 0;
      .mpp-v2 &,
      .spp-product-layout-v2 & {
        padding: 0 20px;
        @include breakpoint($medium-up) {
          min-height: 235px;
          padding: 0;
        }
      }
      .product-grid--carousel & {
        @include breakpoint($medium-up) {
          min-height: 300px;
        }
      }
    }
    &__review-snippet {
      padding: 5px 0;
      .pr-no-reviews,
      .pr-snippet-review__link {
        display: none;
      }
    }
    &__title {
      margin-top: 10px;
      font-family: $font--futura-demi;
      font-size: 18px;
      text-align: left;
      width: 100%;
    }
    &__subtitle {
      font-family: $font--futura-medium;
      display: block;
      font-size: 18px;
      letter-spacing: 0;
      text-align: left;
      text-transform: capitalize;
      width: 100%;
    }
    &__subline {
      font-size: 15px;
      margin: 10px 0;
    }
    &__subtitle-shadename {
      margin-left: 4px;
    }
    &__benefit {
      font-size: 16px;
      text-align: left;
      width: 100%;
    }
    &__benefit-label {
      font-family: $font--futura-demi;
      font-weight: normal;
      width: 100%;
    }
    &__cta-add-to-bag .product-add-to-bag {
      @include cr18-btn-primary;
      font-size: 18px;
      height: 40px;
      padding: 0 25px;
      line-height: 2.2;
      &.product-sold-out {
        background-color: $color-mid-gray;
        &:hover {
          background-color: $color-mid-gray;
          cursor: default;
        }
      }
      &:hover {
        background-color: $color-green;
        text-decoration: none;
      }
      &.product-comming-soon {
        background-color: $color-mid-gray;
        &:hover {
          background-color: $color-mid-gray;
          cursor: default;
        }
      }
    }
    &__price {
      text-align: left;
      margin: 0 10px 0 0;
      @include breakpoint($medium-up) {
        display: table-cell;
        vertical-align: middle;
      }
    }
    &__shaded-detail {
      margin: 9px 0 14px;
      @include breakpoint($medium-up) {
        margin: 14px 0;
      }
    }
    &__description {
      &:hover {
        color: $color-green;
        text-decoration: none;
      }
    }
  }
  // @todo this belongs in the price partial, but we may fork so leaving here for now
  // specificity wars
  .product-brief__price,
  .product-sku-price__value {
    font-family: $font--futura-book;
    font-size: 16px;
    font-weight: normal;
    line-height: 28px;
  }
  .product-sku-price__unit-price {
    display: none;
  }
  .product-unit-price {
    font-size: 15px;
    color: $color-mid-gray;
    margin-top: 5px;
  }
  .p-w-r {
    .pr-snippet-review-count {
      line-height: 1.2;
    }
    .pr-snippet-stars-reco-inline {
      .pr-snippet-read-and-write {
        span.pr-snippet-review-count {
          line-height: 1.2;
          padding-top: 2px;
          @include breakpoint($medium-up) {
            padding-top: 1px;
          }
        }
      }
    }
  }
  .selectBox-label.sku-list-sizes__button-dropdown {
    font-size: 15px;
    line-height: 2;
  }
}
